import React, { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from "react-router";
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { PrimaryButton, } from 'office-ui-fabric-react';
import { Stack, IStackStyles } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { ApplicationContext, ServiceContext } from '@msx/platform-services'
import { messages } from './Signin.messages';
import { getStyles } from './Signin.styles';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  //TODO;
}

type Props = OwnProps & InjectedIntlProps & RouteComponentProps;

const SigninComponent: React.FC<Props> = props => {
  const { intl } = props;
  const { appState } = useContext(ApplicationContext);
  const { authClient } = useContext(ServiceContext);

  classes = getClassNames(getStyles, appState.theme);

  const handleGetStartedButonClick = async () => {
    await authClient.login();
  }


  const renderMain = (): JSX.Element => {
    // const Window = window as any;
    // if (Window.hasStartupDataInprogress === true) {
    //   alert('1');
    //   return null;
    // }

    const btnStyles = {
      root: {
        width: '170px',
        height: '40px',

      },
    };
    const stackTokens = { childrenGap: 20 };
    const stackStyles: Partial<IStackStyles> = { root: { minHeight: 'calc(80vh)' } };
    const containerStyles: React.CSSProperties = { minHeight: '100%' };
    return (
      <div style={containerStyles}>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          tokens={stackTokens}
          styles={stackStyles}
        >
          <Image
            src="https://extensionassets-test.azureedge.net/assets/images/logo.png"
            alt={appState.appConfig.appName}
            width={79}
          />
          <Text variant="xxLargePlus">{appState.appConfig.appName}</Text>
          <Text>{appState.appConfig.appName} {intl.formatMessage(messages.subTitle)}</Text>
          <Image
            src="https://extensionassets-test.azureedge.net/assets/images/welcome-center.png"
            alt={appState.appConfig.appName}
            height={183}
          />
          <PrimaryButton text={intl.formatMessage(messages.getStartedButtonText)} onClick={handleGetStartedButonClick} styles={btnStyles} />
          <Link className={classes.learnLink} to="/learn">
            {intl.formatMessage(messages.learnButtonText)}
          </Link>
        </Stack>
      </div>
    )
  }
  return renderMain();
}

export const Signin = withRouter(injectIntl(SigninComponent));
