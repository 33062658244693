import { IExtensionsRegistration, IHttpClient, IHttpClientRequest } from '@msx/platform-services';
import { DefaultHttpClientRequest } from '../App.types';
import { IDataService, INotificationItem, IUserProfile } from '../interfaces';
import { appConfig } from '../App.config';


export class DataService implements IDataService {
  private httpClient: IHttpClient;
  private baseUrl: string;

  public constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.baseUrl = appConfig.apiConfig.baseUrl;
  }

  public async GetUserProfile(): Promise<IUserProfile> {
    const url = appConfig.isMockData ?
      '/data/userProfile.json' :
      `${this.baseUrl}userSettings`;

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'GET',
        url: url
      };
      this.httpClient
        .request<IUserProfile>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }

  public async UpdateUserProfile(userProfile: IUserProfile): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // TODO: Actual API integration 
      resolve(true);

    });
  }

  public async GetExtensionsRegistration(): Promise<IExtensionsRegistration> {
    const url = `${appConfig.registrationConfig.apiEndpoint}`;

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'GET',
        url: url
      };
      this.httpClient
        .request<IExtensionsRegistration>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }

  public async GetNotifications(): Promise<[INotificationItem]> {
    // TODO: Actual API integration 
    const url = '/data/notifications.json';

    return new Promise((resolve, reject): void => {
      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        method: 'GET',
        url: url
      };
      this.httpClient
        .request<[INotificationItem]>(request)
        .then((response): void => {
          resolve(response.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }

  public async UpdateNotifications(notifications: [INotificationItem]): Promise<[INotificationItem]> {
    const url = `${this.baseUrl}Notifications`;
    return new Promise(async (resolve, reject) => {
      if (appConfig.isMockData)
        resolve(notifications);

      const request: IHttpClientRequest = {
        ...DefaultHttpClientRequest,
        data: notifications,
      };
      try {
        await this.httpClient.post(url, request);
        resolve(notifications);
      }
      catch (err) {
        reject(err);
      }
    });
  }

}
