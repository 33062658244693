import { IAppTheme } from '@msx/platform-services'

export const getStyles = (theme: IAppTheme) => {
  return {
    tileContainer: {
      //background: theme.name === 'dark' ? '#333' : 'transparent',
    },
    headingMain: {
      margin: '0',
    },
  };
};
