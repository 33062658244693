import { IAppTheme } from '@msx/platform-services'

export const getStyles = (theme: IAppTheme) => {
  return {
    root: {
      padding: '20px',
    },
    headingMain: {
    },
    learnLink: {
      textDecoration: 'none',
    }
  };
};
