import { IStackItemStyles } from '@fluentui/react';


export const hpanelTileStackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'left',
    width: 220,
    height: 252,
  },
};