
import React, { useEffect, useState, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { CoherenceHeader, NotificationListItem, CoherencePanelSize, ItemDisplayStatus, ItemStatus } from '@cseo/controls';
import { MsxSearchBox, ServiceContext, ApplicationContext, SuggestionBoxProps } from '@msx/platform-services';
import { messages } from './AppHeader.messages';
import { HeaderProps } from './AppHeader.types';
import { Help } from '..';
import { Settings } from '..'
import { Stack, Panel, PanelType } from 'office-ui-fabric-react';
import { useBoolean } from '@uifabric/react-hooks';
import { IAppConfig } from '../../App.config';
import { getNotifications, fetchNotificationsSuccess, } from '../../../app/store';
import { INotificationItem } from '../../interfaces';
import moment from 'moment';
import { Extension, ExtensionTypes } from '../../../core/components';
import { withRouter } from 'react-router-dom';
import { AppRoutePath } from '../../App.types';

const AppHeaderComponent: (React.FC<HeaderProps>) = (props) => {
  const reduxDispatch = useDispatch();
  const { extensionsRegistrationClient, appState } = useContext(ApplicationContext);
  const context = React.useContext(ServiceContext)
  const notifications = useSelector(getNotifications);
  const [notificationCount, setNotificationCount] = useState(notifications ? notifications.length : 0);
  const [notificationItems, setNotificationItems] = useState<NotificationListItem[]>([]);
  const [dismissOpenedPanel, setDismissOpenedPanel] = useState(undefined);
  const { intl, history, isUserLoggedIn, appName, isAppReady } = props;
  const [isNotificationsOpen, { setTrue: openNotificationsPanel, setFalse: dismissNotificationsPanel }] = useBoolean(false);
  const appConfig = appState.appConfig as IAppConfig;
  const searchExtensions = extensionsRegistrationClient.getSearchExtensions();

  const SearchSuggestionBoxMaxHeight = 300; // default 300
  const SearchSuggestionBoxWidth = 296; // default 296

  useEffect(() => {
    processNotificaitons();
    // eslint-disable-next-line
  }, [notifications]);

  const convertINotificationItem2NotificationListItem = (item: INotificationItem) => {
    const nofication: NotificationListItem = {
      itemKey: item.itemKey,
      displayStatus: item.displayStatus as ItemDisplayStatus,
      status: item.status as ItemStatus,
      timeDelivered: item?.timeDelivered ? moment(item?.timeDelivered) : moment().add(-1, 'hours'),
      messageBodyText: item.messageBodyText,
      subjectIcon: item.subjectIcon,
      subjectHeader: item.subjectHeader,
      senderName: item.senderName,
      actionRequiredText: item.actionRequiredText,
      actionRequiredLink: item.actionRequiredLink
    }
    return nofication;
  }

  const processNotificaitons = () => {
    let count = 0;
    let tempNofications: NotificationListItem[] = [];
    if (notifications && notifications.length > 0) {
      notifications.forEach(item => {
        if (item.status === 'unread') count++;
        tempNofications.push(convertINotificationItem2NotificationListItem(item))
      });
    }
    setNotificationCount(count);
    setNotificationItems(tempNofications);

  }

  const handleOpenNotificationItem = (itemKey: string) => {
    console.log('Open notification ' + itemKey);
  }

  const handleUpdateNotificationItem = (itemKey: string, dStatus: NotificationListItem['displayStatus'], rStatus?: NotificationListItem['status']) => {
    let list = [...notifications];
    list.forEach((item) => {
      if (item.itemKey === itemKey) {
        item.displayStatus = dStatus;
        if (rStatus) {
          item.status = rStatus;
        }
      }
    });
    reduxDispatch(fetchNotificationsSuccess(notifications as INotificationItem[]))
  }

  const updateNotificationItemDisplayStatus = (): void => {
    // Placeholder for code that updates displayStatus to 'old' for all items listed in the id string array
    console.log('Mark notification(s) as old');

    // Setting to -1 to see badge disappear when notification panel opens
    setNotificationCount(-1);
  }


  const handleSignOut = () => {
    context.authClient.logOut();
  }

  const getHelpBody = () => {
    return <Help isAppReady={isAppReady} />
  }
  const handleDismissPanel = () => {
    setDismissOpenedPanel(true);
  }

  const searchTextHandler = (text: string): void => {
    history.push({
      pathname: AppRoutePath.Search,
      search: `?text=${text}`,
    });

  }

  const renderSuggestions = (text: string, props: SuggestionBoxProps) => {
    return (
      <>
        {searchExtensions.map((extension) => {
          return (
            <div data-is-focusable={true} key={`div-${extension.globalSearchConfig.key}`}>
              <Extension
                key={`extension-${extension.globalSearchConfig.key}`}
                componentKey={extension.globalSearchConfig.key}
                ignoreWrapControl={true}
                slimLayout={true}
                searchText={text}
                componentType={ExtensionTypes.Search}
                suggestionBoxWidth={props.width}
              />
            </div>
          );
        })}
      </>
    );
  }

  const getSearchSettings = () => {
    if (!appConfig.globalSearchConfig.active) {
      return null;
    }
    const item = {
      onSearch: (_: string) => undefined,
      onRender: () => {
        return (
          <MsxSearchBox
            ariaLabel='SearchBox'
            searchTitle={''}
            onSearch={searchTextHandler}
            onRenderSuggestions={renderSuggestions}
            suggestionBoxWidth={SearchSuggestionBoxWidth}
            suggestionBoxMaxHeight={SearchSuggestionBoxMaxHeight}
          />
        );
      }
    };
    return item;
  }

  const getSettingsBody = () => {
    return <Settings onDismissPanel={handleDismissPanel} />
  }

  const getNotificationSettings = () => {
    if (!appConfig.notificaitonConfig.active) return null;
    if (appConfig.notificaitonConfig.customPanel) return null;
    if (!notifications) return null;


    const item = {
      panelSettings:
      {
        titleText: intl.formatMessage(messages.settingsNotificationsTitle),
        items: notificationItems,
        newNotifications: notificationCount,
        onPanelOpened: updateNotificationItemDisplayStatus,
        openItem: handleOpenNotificationItem,
        updateItem: handleUpdateNotificationItem,
        panelSize: CoherencePanelSize.medium,
      },
      buttonSettings: {
        title: intl.formatMessage(messages.notificationToolTip)
      }
    };
    return item;
  }



  const getFarItems = (): ICommandBarItemProps[] => {
    let items = [
      {
        key: 'chatBot',
        id: 'header-chat-bot',
        text: intl.formatMessage(messages.chatBot),
        ariaLabel: intl.formatMessage(messages.financeSupportBotAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: 'Robot',
        },
        onClick: () => {
          (window as any).OpenFDA("hostappid=" + appConfig.botConfig.hostAppId);
        }
      },
      {
        key: 'gudedTour',
        id: 'header-guided-tour',
        text: intl.formatMessage(messages.retakeTour),
        ariaLabel: intl.formatMessage(messages.retakeTourAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: 'ReadingMode',
        },
        onClick: () => {
          console.log('Guided tour button clicked')
        }
      },
      {
        key: 'customNotificaiton',
        id: 'header-my-notifications',
        text: intl.formatMessage(messages.settingsNotificationsTitle),
        ariaLabel: intl.formatMessage(messages.settingsNotificationsTitle),
        iconOnly: true,
        iconProps: {
          iconName: 'Ringer',
        },
        onClick: handleNotificationsClick,
      },
    ];
    if (!isAppReady || !isUserLoggedIn) {
      items = items.filter(function (obj) {
        return obj.key !== 'chatBot' && obj.key !== 'gudedTour';
      });
      items = items.filter(function (obj) {
        return obj.key !== 'customNotificaiton';
      });
      return items;
    }
    if (!appConfig.notificaitonConfig.active || !appConfig.notificaitonConfig.customPanel) {
      items = items.filter(function (obj) {
        return obj.key !== 'customNotificaiton';
      });
    }
    if (!appConfig.botConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== 'chatBot';
      });
    }
    if (!appConfig.guidedTourConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== 'gudedTour';
      });
    }
    return items;
  }

  const handleNotificationsClick = () => {
    console.log('My notifications clicked');
    openNotificationsPanel();
  }

  const renderNotificationsPanel = () => {
    if (!appConfig.notificaitonConfig.active) return null;
    if (!appConfig.notificaitonConfig.customPanel) return null;

    const panelStyle = { root: { marginTop: '48px' } };
    return (
      <Panel
        isLightDismiss
        isOpen={isNotificationsOpen}
        type={PanelType.medium}
        onDismiss={dismissNotificationsPanel}
        closeButtonAriaLabel="Close"
        headerText={intl.formatMessage(messages.settingsNotificationsTitle)}
        styles={panelStyle}
      >
        {renderNotificationsPanelBody()}
      </Panel>
    )
  }

  const renderNotificationsPanelBody = (): JSX.Element => {
    return (
      <Stack horizontalAlign={'start'}>
        <Stack.Item>
          {'display notification items here'}
        </Stack.Item>
      </Stack>
    );
  }

  const renderFullHeader = (): JSX.Element => {
    return <CoherenceHeader
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      searchSettings={getSearchSettings()}
      farRightSettings={{
        additionalItems: getFarItems(),
        notificationsSettings: getNotificationSettings(),
        settingsSettings: {
          panelSettings: {
            titleText: intl.formatMessage(messages.settingsSettingsTitle),
            body: getSettingsBody(),
            panelSize: CoherencePanelSize.medium,
          }
        },
        helpSettings: {
          panelSettings: {
            titleText: intl.formatMessage(messages.helpSettingsTitle),
            backButton: {
              text: intl.formatMessage(messages.helpSettingsBackButton),
              ariaLabel: intl.formatMessage(messages.helpSettingsBackButtonAriaLabel),
              onClick: () => {
                console.log('Help Panel Back Button clicked');
              }
            },
            body: getHelpBody(),
          }
        },
        feedbackSettings: {
          panelSettings: {
            ocvButtonIsFocused: false,
            onClick: () => {
              const Window = window as any;
              Window.startMultiFeedback_AllOptional();
              return false;
            },
          }
        },
        profileSettings: {
          panelSettings: {
            fullName: props.user.name,
            emailAddress: props.user.email,
            imageUrl: '',
            logOutLink: '#',
            onLogOut: () => handleSignOut(),
            onRenderFooter: () => { return <a href='https://privacy.microsoft.com/en-us/privacystatement' rel="noopener noreferrer" target="_blank">Privacy Notice</a> },
          }
        }
      }}
      dismissOpenedPanel={dismissOpenedPanel}
      onDismissOpenedPanel={() => {
        setDismissOpenedPanel(undefined);
      }}
    />
  }

  const renderInitialHeader = (): JSX.Element => {
    return <CoherenceHeader
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
      }}
    />
  }

  const renderNotLoggedInHeader = (): JSX.Element => {
    return <CoherenceHeader
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
        settingsSettings: {
          panelSettings: {
            titleText: intl.formatMessage(messages.settingsSettingsTitle),
            body: getSettingsBody(),
          }
        },
      }}
    />
  }

  const renderMain = (): JSX.Element => {
    if (!isAppReady)
      return renderInitialHeader();
    if (!isUserLoggedIn)
      return renderNotLoggedInHeader();

    return (
      <>
        {renderFullHeader()}
        {renderNotificationsPanel()}
      </>

    );

  }

  return renderMain();

}

export const AppHeader = withRouter(injectIntl(AppHeaderComponent));
