import { IStyle, mergeStyleSets } from 'office-ui-fabric-react';
import { getScrollBarWidth } from '../core/utils';

const navCollapsedWidth = 48;

const scrollablePaneStyles = {
  position: 'fixed',
  top: 48,
  bottom: 0,
  right: 0
};

export const shellStyles = mergeStyleSets({
  scrollablePaneCollapsed: {
    ...scrollablePaneStyles,
    left: navCollapsedWidth + getScrollBarWidth() , 
  } as IStyle,
  scrollablePaneExpand: {
    ...scrollablePaneStyles,
    left: 228 + getScrollBarWidth() + 10
  } as IStyle,
  rootDiv: {
    paddingRight: '30px',
    paddingLeft: '10px'
  } as IStyle,
  dividerLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'black',
    marginBottom: '20px'
  } as IStyle,
  rowGap: {
    height: '30px'
  } as IStyle,
  mainPanelCollapsed: {
    padding: '20px 0',
  } as IStyle,
  mainPanelExpand: {
    padding: '20px 0',
  } as IStyle,
});

