import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { IStyle, mergeStyleSets, SearchBox, FontWeights } from 'office-ui-fabric-react';
import { FontSizes } from '@cseo/styles';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { messages } from './Help.messages';

export const HelpSampleLinkStyles = mergeStyleSets(
  {
    helpSampleLink: {
      margin: '8px 0',
      display: 'block',
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold
    } as IStyle
  }
);

interface OwnProps extends InjectedIntlProps {
  isAppReady: boolean;
}

type Props = OwnProps & RouteComponentProps;

const HelpComponent: (React.FC<Props>) = (props) => {
  const { intl } = props;

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const renderMain = (): JSX.Element => {
    return (
      <div>
        <SearchBox
          placeholder={intl.formatMessage(messages.helpPageSearchPlaceHolder)}
          onSearch={newValue => console.log('value is ' + newValue)}
          onFocus={() => console.log('onFocus called')}
          onBlur={() => console.log('onBlur called')}
          onChange={() => console.log('onChange called')}
        />

        <h2>{intl.formatMessage(messages.helpPageSubTile)}</h2>
      </div>

    );
  }

  return renderMain();

}
export const Help = withRouter(injectIntl(HelpComponent));

