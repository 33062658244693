import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import {
  AppDataServiceProvider, AppSettingsProvider,
  AppShellProvider, AppStoreProvider
} from './core/provider'
import { App } from './app/App';
import { mergeStyles } from 'office-ui-fabric-react';
import './index.css';

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#app)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

initializeIcons();

ReactDOM.render(
  <AppShellProvider >
    <AppStoreProvider>
      <AppDataServiceProvider>
        <AppSettingsProvider>
          <App />
        </AppSettingsProvider>
      </AppDataServiceProvider>
    </AppStoreProvider>
  </AppShellProvider>,
  document.getElementById('app'));
