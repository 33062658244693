import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  homeTitle: {
    id: 'homePage.homeTitle',
    defaultMessage: "Tax Insight Hub - Offer flexible audit options",
  },
  homeSubTitle: {
    id: 'homePage.homeSubTitle',
    defaultMessage: "With Tax Insight Hub you can perform Self-Serve Reports, Standard Reports, Tax Reconciliation and Data Quality needs.",
  },
  featureUpdates: {
    id: 'homePage.featureUpdates',
    defaultMessage: "Feature Updates",
  }
});

