import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { getTheme } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react';
import { ApplicationContext } from '@msx/platform-services'
import { messages } from './HomeContent.messages';

interface OwnProps extends InjectedIntlProps {
  //TODO: any
}

type Props = OwnProps & RouteComponentProps;

const HomeContentComponent: (React.FC<Props>) = (props) => {
  const { intl } = props;
  const { appState  } = useContext(ApplicationContext);

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const renderMain = (): JSX.Element => {
    if (!appState.appConfig.registrationConfig.active) return null;
    return (
      <Stack tokens={{"childrenGap": 24}} verticalFill disableShrink={true}>
        <Stack.Item>
            <span style={{ color: 'black', fontWeight: 'bold', fontSize: 18 }}>
                Welcome &nbsp;{appState.user?.name}            
            </span>
        </Stack.Item>
        <Stack.Item styles={{root:{color: getTheme().palette.neutralSecondary, width: '100%', textAlign: 'center', fontSize: 26}}}>
            <p>{intl.formatMessage(messages.homeTitle)}</p>
            <p style={{fontSize: 14, marginTop: 8}}>{intl.formatMessage(messages.homeSubTitle)}</p>
        </Stack.Item>
      </Stack>
    );
  }

  return renderMain();

}

export const HomeContentHeader = withRouter(injectIntl(HomeContentComponent));

