import { defineMessages } from "react-intl";

export const messages = defineMessages({
  home: {
    id: 'leftNavMenu.home',
    defaultMessage: 'Home'
  },
  externalLinksGroupTitle: {
    id: 'leftNavExtra.group.title',
    defaultMessage: 'Explore more'
  }
});
