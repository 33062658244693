import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  retakeTour: {
    id: 'header.retakeTour',
    defaultMessage: 'Guided tour',
  },
  retakeTourAriaLabel: {
    id: 'header.retakeTourAriaLabel',
    defaultMessage: 'retake guided tour',
  },
  localization: {
    id: 'header.localization',
    defaultMessage: 'Language menu',
  },
  notificationToolTip: {
    id: 'header.notificationToolTip',
    defaultMessage: 'Notifications',
  },
  localizationAriaLabel: {
    id: 'header.localizationAriaLabel',
    defaultMessage: 'change Language menu',
  },
  chatBot: {
    id: 'header.chatBot',
    defaultMessage: 'Support bot',
  },
  financeSupportBotAriaLabel: {
    id: 'header.financeSupportBotAriaLabel',
    defaultMessage: 'Support bot (opens a new window)',
  },
  feedback: {
    id: 'header.feedback',
    defaultMessage: 'Feedback',
  },
  profile: {
    id: 'header.profile',
    defaultMessage: 'Profile menu',
  },
  expandMenu: {
    id: 'header.expandMenu',
    defaultMessage: 'expanded',
  },
  commandBarAriaLabel: {
    id: 'header.commandBarAriaLabel',
    defaultMessage: 'Main header, please use the left and right arrow keys to navigate between items',
  },
  globalSearch: {
    id: 'header.globalSearch',
    defaultMessage: 'Global Search',
  },
  globalSearchPlaceHolderText: {
    id: 'header.globalSearchPlaceHolderText',
    defaultMessage: 'Search',
  },
  helpSettingsTitle: {
    id: 'header.helpSettingsTitle',
    defaultMessage: 'Help',
  },
  helpSettingsBackButton: {
    id: 'header.helpSettingsBackButton',
    defaultMessage: 'Help Panel Back Button',
  },
  helpSettingsBackButtonAriaLabel: {
    id: 'header.helpSettingsBackButtonAriaLabel',
    defaultMessage: 'Help Panel Back Button for multiple level content navigation',
  },
  settingsNotificationsTitle: {
    id: 'header.settingsNotificationsTitle',
    defaultMessage: 'Notifications',
  },
  settingsSettingsTitle: {
    id: 'header.settingsSettingsTitle',
    defaultMessage: 'Settings',
  },
  settingsSettingsPrimaryButton: {
    id: 'header.settingsSettingsPrimaryButton',
    defaultMessage: 'Save',
  },
  settingsSettingsPrimaryButtonAriaLabel: {
    id: 'header.settingsSettingsPrimaryButtonAriaLabel',
    defaultMessage: 'Save Settings',
  },
  settingsSettingsSecondaryButton: {
    id: 'header.settingsSettingsSecondaryButton',
    defaultMessage: 'Cancel',
  },
  settingsSettingsSecondaryButtonAriaLabel: {
    id: 'header.settingsSettingsSecondaryButtonAriaLabel',
    defaultMessage: 'Cancel current Settings',
  },
});
