import { InjectedIntl } from 'react-intl';
import { messages } from './AppLeftNav.messages';
import { IAppExtension } from '@msx/platform-services';
import { getLeftNavMenuItems } from '../../App.navigation';
// import { getExtraLeftNavMenuItems } from '../../App.navigation';


export const getConfig = (intl: InjectedIntl, isAppReady: boolean,
  history: any, extensions: IAppExtension[],
  isUserLoggedIn: boolean) => {

  if (!isAppReady)
    return []

  const navLinkGroups = getMenuItems(intl, isAppReady, history, extensions, isUserLoggedIn);
  
  return navLinkGroups
};

const getMenuItems = (intl: InjectedIntl, isAppReady: boolean, history: any,
  extensions: IAppExtension[], isUserLoggedIn: boolean) => {
  if (!isAppReady)
    return []

  const getlocaleText = (key: string, defaultValue: string) => {
    let result = defaultValue;
    const newKey = 'leftNav_' + key.replace(/-/g, '_');
    const descriptor = messages[newKey];
    if (descriptor) result = intl.formatMessage(descriptor);
    return result;
  };

  const ICONS: { [key: string]: string } = {
    'Standard Reports': 'ReportLibrary',
    'Self-Serve Reports': 'ComplianceAudit'
  }

  const navMenuLinks =
    [
      // creating first left navigation menu group
      // to display all app pages links
      {
        key: 'left_nav_menu',
        groupTitleAttributes: {
          id: 'left_nav_group_menu',
        },
        links: [
        ],
      },
    ];
  // creating home page links
  navMenuLinks[0].links.push(
    {
      name: intl.formatMessage(messages.home),
      key: 'left_nav_menu_home',
      ariaLabel: intl.formatMessage(messages.home),
      icon: 'Home',
      onClick: () => {
        history.push('/');
      },
      isSelected:
        window.location.href === window.location.protocol + '//' + window.location.host + '/' ? true : false,
    },
  );

  // creating page links for logged in user only
  if (isUserLoggedIn) {
    const menuItems = getLeftNavMenuItems(intl, history);
    menuItems.forEach((item) => navMenuLinks[0].links.push(item));

  }

  //creating dynamic extension links
  extensions.forEach((extension) => {
    if(extension.active)
    {
      navMenuLinks[0].links.push({
        name: getlocaleText(extension.key, extension.name),
        key: extension.key,
        ariaLabel: extension.name,
        icon: ICONS[extension.name],
        links: extension.pages.map((page) =>({
          name: getlocaleText(page.key, page.description),
          key: page.key,
          ariaLabel: page.description,
          onClick: () => {
            history.push({ pathname: `/${page.routePath}` });
          },
          isSelected:
            window.location.href ===
              window.location.protocol +
              '//' +
              window.location.host +
              `/${page.routePath}`
              ? true
              : false,
        }))
      });
    }
  });
  

  return navMenuLinks

};


