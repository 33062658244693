import * as React from 'react';
import { ActivityItem, mergeStyleSets, IActivityItemProps, getTheme, Stack } from '@fluentui/react';
import { Feed_Store } from '../../../utils/FeedStore';
import { Card, CardStandardHeader } from '@cseo/controls';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { messages } from './HomeContent.messages';

const classNames = mergeStyleSets({
    exampleRoot: {
        marginTop: '10px',
    },
    nameText: {
        fontWeight: 'bold',
    },
});

const activityItemFeeds: (IActivityItemProps & { key: string | number })[] = Feed_Store

interface OwnProps extends InjectedIntlProps {
    //TODO: any
  }
  
type Props = OwnProps & RouteComponentProps;

const ReleaseFeedsComponent: (React.FC<Props>) = (props) => {
    const {intl} = props;
    return (
        <Stack style={{"marginTop": '30px'}}>
        <Stack.Item styles={{root:{color: getTheme().palette.neutralSecondaryAlt, width: '100%', textAlign: 'center'}}}>
        <Card styles={{root: {
                boxShadow: '0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108)',
                borderRadius: 2,
                width: 952,
                margin: '0 auto', position: 'relative'
            }, header: {border: 'none'}, layout: {paddingTop: 0}}} 
            header={<CardStandardHeader styles={{cardTitle: {fontSize: 12, fontWeight: 'bold'}, 
            root: {background: getTheme().palette.neutralQuaternaryAlt}}}
            cardTitle={intl.formatMessage(messages.featureUpdates)}/>}
        >
            {activityItemFeeds.map((item: { key: string | number }) => (
                <ActivityItem {...(item as IActivityItemProps)} key={item.key} className={classNames.exampleRoot} />
            ))}
        </Card>
        </Stack.Item>
        </Stack>
    );
};

export const ReleaseFeeds = withRouter(injectIntl(ReleaseFeedsComponent));
