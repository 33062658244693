import * as React from "react";
import { PersonaPresence, PersonaSize, Icon } from '@fluentui/react';
import { getTheme } from '@fluentui/react/lib/Styling';

export const Feed_Store = 
[
    {
        key: 1,
        activityDescription: [
            <span>has incorporated user authorization features.</span>
        ],
        activityPersonas: [{ imageInitials: 'AS', text: 'Arif Sheikh', size: PersonaSize.size24, coinSize: 20 }],
        isCompact: true,
        styles: { activityContent: { textAlign: 'left' }, activityText: { paddingLeft: '10px' }, root: {color: getTheme().palette.themePrimary} }
    },
    {
        key: 2,
        activityDescription: [
            <span>has onboarded GST reports on MTH </span>
        ],
        activityPersonas: [{ imageInitials: 'AS', text: 'Arif Sheikh', size: PersonaSize.size24, coinSize: 20 }],
        isCompact: true,
        styles: { activityContent: { textAlign: 'left' }, activityText: { paddingLeft: '10px' }, root: {color: getTheme().palette.themePrimary} }
    },
    {
        key: 3,
        activityDescription: [
            <span>Notification system and Admin page development is in progress, </span>,
            <span>features will be rolled out soon.</span>
        ],
        activityIcon: <Icon iconName={'ActivityFeed'} styles={{root: {fontSize: 16}}} />,
        isCompact: true,
        styles: { activityContent: { textAlign: 'left' }, activityText: { paddingLeft: '10px' }, root: {color: getTheme().palette.themePrimary} }
    },
    {
        key: 4,
        activityDescription: [
            <span>Monitor screen features have been rolled out.</span>
        ],
        activityIcon: <Icon iconName={'SpeedHigh'} styles={{root: {fontSize: 16}}} />,
        isCompact: true,
        styles: { activityContent: { textAlign: 'left' }, activityText: { paddingLeft: '10px' }, root: {color: getTheme().palette.themePrimary} }
    },
    {
        key: 5,
        activityDescription: [
            <span>have rolled out audit request Query flow feature for private preview.</span>
        ],
        activityPersonas: [
            { imageInitials: 'AS', text: 'Arif Sheikh', size: PersonaSize.size24, coinSize: 20 },
            // { imageUrl: TestImages.personaMale },
            { imageInitials: 'KC', text: 'Kalyanasish Chanda', size: PersonaSize.size24, coinSize: 20 },
            { imageInitials: 'PP', text: 'Peeyush Kumar Pandey', size: PersonaSize.size24, coinSize: 20 }
        ],
        isCompact: true,
        styles: { activityContent: { textAlign: 'left' }, activityText: { paddingLeft: '10px' }, root: {color: getTheme().palette.themePrimary} }
    }
]