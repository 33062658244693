import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '@msx/platform-services';
import { FOOTER_HEIGHT } from './AppFooter.types';

interface IAppFooterStyles {
  container: IStyle;
  message: IStyle;
  footerExpanded: IStyle;
  footerCollapsed: IStyle;
}


export const getStyles = (theme: IAppTheme): IAppFooterStyles => {
  return {
    container: {
      width: '100%',
      position: "fixed",
      bottom: 0,
      background: '#EBEBEB',
      padding: '5px 45px',
      overflow: 'hidden',
      height: `${FOOTER_HEIGHT}px`,
    },
    message: {
      fontFamily: "Segoe UI",
      color: `${theme.palette.black}`,
      fontSize: "11px",
      margin: "0",
    },
    footerExpanded: {
      width: '100%', 
      marginLeft: '14%',
    },
    footerCollapsed: {
      width: '100%',
      marginLeft: '2%',
    }
  }
};


