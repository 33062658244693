import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTile: {
    id: 'dashboardPage.title',
    defaultMessage: 'Dashboard'
  },
  noDataFound: {
    id: 'dashboardPage.noDataFound',
    defaultMessage: 'Currently, there are no extensions availiable.',
  },
});

