import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { getCurrentTheme } from '../../../core/store';
import { messages } from './AppFooter.messages';
import { getStyles } from './AppFooter.styles';
import { FooterProps } from './AppFooter.types';
import { ApplicationContext } from '@msx/platform-services';
import dayjs from "dayjs";

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const AppFooterComponent: React.FC<FooterProps> = props => {
  const { appState } = useContext(ApplicationContext);
  const { intl } = props;
  const theme = useSelector(getCurrentTheme);
  classes = getClassNames(getStyles, theme);

  const renderMain = (): JSX.Element => {
    if (!appState.appConfig.showFooter) return null;
    return (
      <footer className= {classes.container}>
        <Stack className={props.isNavCollapsed ? classes.footerCollapsed : classes.footerExpanded} tokens={{childrenGap: 24}} horizontal={true} disableShrink={true}>
          <Stack.Item>
              <span style={{verticalAlign: 'sub', fontSize: 12}}>
                  <a href="https://privacy.microsoft.com/en-US/data-privacy-notice" target="_blank" style={{color: '#006cbe'}}>Privacy Notice</a>
              </span>
          </Stack.Item>
          <Stack.Item>
              <span style={{verticalAlign: 'sub', fontSize: 12}}>
                  <a href="https://docs.microsoft.com/en-us/legal/termsofuse" target="_blank" style={{color: '#006cbe'}}>Terms of Use</a>
              </span>
          </Stack.Item>
          <Stack.Item styles={{root: {width: '100%', display: 'flex', justifyContent: 'center'}}}>
              <span style={{verticalAlign: 'sub', fontSize: 12, fontWeight: 400, alignSelf: 'self-end'}}>
                  &copy; Microsoft {dayjs().year()}
              </span>
          </Stack.Item>
      </Stack>
      </footer>
    );
  }
  return renderMain();
}

export const AppFooter = injectIntl(AppFooterComponent);
