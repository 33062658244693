import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  helpPageSearchPlaceHolder: {
    id: 'help.helpPageSearchPlaceHolder',
    defaultMessage: 'Search help'
  },
  helpPageTile: {
    id: 'help.Title',
    defaultMessage: 'Help'
  },
  helpPageSubTile: {
    id: 'help.helpPageSubTile',
    defaultMessage: 'Featured help'
  },
});

