import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  settingsPanelAriaLable: {
    id: 'settingsPanel.settingsPanelAriaLable',
    defaultMessage: 'Portal settings'
  },
  generalTabTile: {
    id: 'settingsPanel.generalTabTile',
    defaultMessage: 'General'
  },
  languageTabTile: {
    id: 'settingsPanel.languageTabTile',
    defaultMessage: 'Language'
  },
  extensionsTabTile: {
    id: 'settingsPanel.extensionsTabTile',
    defaultMessage: 'Extensions'
  },
  themeSelectionTile: {
    id: 'settingsPanel.themeSelectionTile',
    defaultMessage: 'Choose a theme'
  },
  languageSelectionTile: {
    id: 'settingsPanel.languageSelectionTile',
    defaultMessage: 'Language'
  },
  languageSelectionSubTile: {
    id: 'settingsPanel.languageSelectionSubTile',
    defaultMessage: 'Choose the language displayed in the portal.'
  },
  applyButtonText: {
    id: 'settingsPanel.applyButtonText',
    defaultMessage: 'Apply'
  },
  discardButtonText: {
    id: 'settingsPanel.discardButtonText',
    defaultMessage: 'Discard'
  },
  themeLightText: {
    id: 'settingsPanel.themeLightText',
    defaultMessage: 'Light'
  },
  themeDarkText: {
    id: 'settingsPanel.themeDarkText',
    defaultMessage: 'Dark'
  },

});

